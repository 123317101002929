.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* -----------custom fonts */
@font-face {
  font-family: "FONTSPRING DEMO";   /*Can be any text*/
  src: local("Fontspring-DEMO-integralcf-bold"),
    url("./fonts/Fontspring-DEMO-integralcf-bold.otf");
}
/* ---------------topframe */
.specialFrame{
  background-repeat:no-repeat;
  background-size:100%;
  width:100%;
  height:100%;
  flex:1;
  display:flex;
  flex-direction: column;
  background-color: #f3f0ed;
}
.topFrame{
  background-repeat:no-repeat;
  background-size:100%;
  width:100%;
  height:100%;
  min-height: 100vh;
  display:flex;
  flex:1;
  align-items:center;
  justify-content:center;
}
.winningFrame{
  background-repeat:no-repeat;
  background-size:100%;
  width:100%;
  height:100%;
  min-height: 100vh;
  display:flex;
  flex:1;
  flex-direction: column;
  padding-bottom: 150px;
}
.colCenter{
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.swimissuepr{
  font-family: 'Manrope';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 33px;
/* identical to box height */


color: #2A3436;

}
.titleWeb{
  
font-family: 'Bungee';
font-style: normal;
font-weight: 400;
font-size: 64px;
line-height: 77px;
/* identical to box height */
color: #4B7205;
mix-blend-mode: normal;
margin-bottom: 56px;
margin-top: 32px;

}
.rowing{
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}

.rowingStart{
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
}
.colStart{
  align-items: flex-start;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}
.colFlexStart{
  align-items: flex-start;
  justify-content:start;
  display: flex;
  flex-direction: column;
}
.activeBtn{

align-items: center;
justify-content: center;
display: flex;
padding: 0px 32px;
height: 56px;
background: #135D62;
border-radius: 100px;
}
.activeBtnText{
  font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 16px;
text-align: center;
color: #FFFFFF;
}
.inActiveBtn{
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 12px 32px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(19, 93, 98, 0.4);
  border-radius: 100px;
}

.inActiveBtnText{
  font-family: 'Plus Jakarta Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */
  text-align: center;
  color: #135D62;
  
}
.btnsFrame{
  width: 541.68px;
}
.joiningTitle{
  font-family: 'Bungee';
font-style: normal;
font-weight: 400;
line-height: 67px;
color: #2A3436;
text-transform: uppercase;
}
.inputStyling{
  display: flex;
flex-direction: row;
align-items: flex-start;
height: 64px;
padding-left: 24px;
background-color: transparent;
outline: none;border: 1px solid #B5B5B5;
border-radius: 8px;
background: #E4DED5;
font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;
/* identical to box height, or 178% */


color: #44433F;

}
.submitBtn{
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 127px;
  height: 56px;
background: #135D62;font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 32px;
text-align: center;
color: #FFFFFF;border-radius: 100px;
cursor: pointer;
}
.contactText{
  font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: bold;
font-size: 11.5px;
color:#888791;padding-left: 0px;
}
.podcastDiv{
  width: 100%;
  max-width: 100vw;
height: 100%;
background: #E4DED5;

}
.podcasTitle{
  font-family: 'Bungee';
font-style: normal;
font-weight: 400;
font-size: 56px;
line-height: 67px;
color: #2A3436;
}
.imgStyling{
  width: 302px;
height: 168px;
}
.worksText{
  font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
color: #44433F;
}
.numbrText{
  font-family: 'Rakkas';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 32px;
color: #009DAC;
padding-right: 24px;
}
.headingPara{
  font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 32px;
color: #000000;
}
.descriptionwinning{
  
font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;
color: #44433F;
}
.line{
      background-color: #44433F;
    height: 1px;
    width: 100%;
}
.cmBFrame{
  
  background-repeat:no-repeat;
  background-size:100%;
  width:100%;
  height:1000px;
  display:flex;
  flex:1;
  flex-direction: column;
}
.chosendescription{
  /* width: 628px;
height: 288px; */
font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;
color: #44433F;
/* padding-left: 190px;
padding-top: 500px; */
padding:24px;
width: 100%;
max-width: 802px;
/* height: 258px; */
/* height: 408px; */
text-align: center;
background: rgba(255, 255, 255, 0.9);
border-radius: 24px;
}
.chosendescriptionMobile{
  /* width: 628px;
height: 288px; */
font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;
color: #44433F;
/* padding-left: 190px;
padding-top: 500px; */
/* height: 258px; */
/* height: 408px; */
text-align: center;

}
.logoContainer{
  display: flex;
/* flex-direction: column; */
align-items: center;
justify-content: center;
/* padding: 32px; */
/* gap: 8px; */
width: 271.44px;
height: 104px;
border: 1px solid rgba(0, 0, 0, 0.2);
border-radius: 100px;
}
.centerMe{
  display: flex;
  align-items: center;
  justify-content: center;}
  .newFrame{
    background-repeat:no-repeat;
  background-size:100%;
  width:100%;
  height:100%;
  min-height: 82.5vh;
  flex:1;
  display:flex;
  align-items: flex-start;
  justify-content: center;
  }
  .subscriptionDiv{
    display: flex;
flex-direction: column;
align-items: center;
padding:32px 32px;
/* width: 397px;
height: 216px; */
background: #E6DBD0;
border-radius: 24px;
justify-content: space-between;
  }
  .subscribeTxt{
font-family: 'Bungee';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 32px;
text-align: center;
color: #2A3436;
mix-blend-mode: normal;
margin-bottom: 24px;
width: 190px;
  }
  .emailInput{
    display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 16px 24px;
background: #FFFFFF;
border-radius: 8px;
  }
  .submitttText{
font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 32px;
text-align: center;
color: #003F43;
text-transform: capitalize;
cursor: pointer;
  }
  .emailInputStyle{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;
color: #44433F;
outline: none;
border: none;


  }
  .howItWorks{
    background-color: #f3f0ed;
  }
  .bottomFrame{
    background-repeat:no-repeat;
    background-size:100%;
    width:100vw;
    height:100%;
    min-height: 1400px;
    flex:1;
    display:flex;
    flex-direction: column;
    background-color: #f3f0ed;
  }
  .savOcean{
    font-family: 'FONTSPRING DEMO';
    font-style: normal;
    font-weight: 700;

    color: #2A3436;
    mix-blend-mode: normal;

  }
  .joinToday{
font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
text-align: center;
color: #44433F;

  }
  .podcastIMgDv{
    background-repeat:no-repeat;
    background-size:100%;
    width:100%;
    height:100%;
    max-width: 90vw;
    max-height: 500px;
    flex:1;
    display:flex;
    /* flex-direction: column; */
  align-self: center;
  }
  .descriptionPodcast{
  
font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 32px;
/* or 178% */
color: #44433F;

  }
  .whiteBox{
    padding:16px;
    background-color: #fff;font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;   
    color: #000000;
    
  }
  .newFFrame{

  background-repeat:no-repeat;
  background-size:100%;
  width:100%;

  display:flex;
  flex:1;
  align-items: center;
  justify-content:center;
  }
  .mobileChoosenHeading{
    font-family: 'FONTSPRING DEMO';
font-style: normal;
font-weight: 700;
font-size: 32px;
line-height: 38px;
color: #2A3436;
text-transform: uppercase;
mix-blend-mode: normal;

text-align: left;

  }
  .bototmMostHeading{
    
font-family: 'FONTSPRING DEMO';
font-style: normal;
font-weight: 700;
font-size: 24px;
line-height: 29px;
text-align: left;
color: #2A3436;
mix-blend-mode: normal;
  }
  .pleaseSubmit{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 32px;
color: #000000;
  }
  .submission{
    font-family: 'Plus Jakarta Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #44433F;
  }
  .topHeading{
    font-family: 'FONTSPRING DEMO - Integral CF Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 88px;
    line-height: 106px;
   text-transform: uppercase;  
    color: #000000;
        mix-blend-mode: normal;
        text-align: center;
        padding-top: 200px;
  }
  .imgTop{
    width: 65%;
    height: 65%;
    object-fit: contain;
    align-self: center;
    padding-top: 0px;
  
  }
  .topImg{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media only screen and (min-width: 600px) {
    .imgTop{
      margin-bottom: 60px;
      padding-top: 10px;
    }
  }
